import React from "react";
import Footer from "../../components/Footer/Footer.js";
import Navbar from "../../components/navbar/index.jsx";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import rrp from '../../assets/images/i1.jpeg';
import goodluck from '../../assets/images/Disclaimer.png';
// import TextField from '@mui/material/TextField';
// import Endpage from '../MainPage/MainPageContent/EndPage.js';
import { experimentalStyled as styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  export default function UnlistShareCard(){
    return(
        <>
        <Navbar/>
        <Box sx={{ width: '96%', margin:"4rem 0rem 2rem 2rem" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="img"/>
          <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box>
          </Box>
          </Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}  >
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}} >
            <Box sx={{display:"flex"}}><img style={{width:150,height:104}} src={rrp} alt="rrp" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}  >
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150,height:104}} src={goodluck} alt="goodluck" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}  >
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="imge" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}  >
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150,height:104}} src={rrp} alt="rrp12345"/>
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image1" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image2" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image3" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image4" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image5" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image6" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image7" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image8" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image9" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image10" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image11" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image12" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image13"/>
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image14" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image15" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image16"/>
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image17" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image18" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image19" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image20" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image21" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' alt="image22" />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        {/* <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid>
        <Grid item xs={3.7} sx={{margin:"0rem 0rem 1.5rem 0.5rem"}}>
          <Item sx={{"border":"1px solid darkgray",'&:hover':{boxShadow:"5px 5px 10px 10px darkgray","z-index":0.1}}}>
            <Box sx={{display:"flex"}}><img style={{width:150}} src='https://unlistedzone.com/storage/company-logo//sambhav_sponge_power_pvt._ltd.-logo-1709877225.png' />
            <Box sx={{display:"flex",flexDirection:"column"}}>
          <Link style={{textDecoration:"none",color:'#757474'}}  to='/polymatech'><Typography sx={{'&:hover':{
            color:"green",cursor:"pointer"
          }}}>Sambhav Steel Private Equity </Typography></Link><Typography>₹ 900 <br/>(+0) (0%)</Typography></Box></Box></Item>
        </Grid> */}
      </Grid>
        </Box>
        <Footer/>
        </>
    )
  }