import React,{useState,useEffect} from "react";
import Footer from "../../components/Footer/Footer.js";
import Navbar from "../../components/navbar/index.jsx";
// import Sidebar from "../../components/sidebar/index.jsx";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Button  from '../../components/button/button';
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import {dataShow} from "../MainPage/DataMain.js";
// import { useTheme } from "@mui/material/styles";

// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import CardActions from "@mui/material/CardActions";
// import Button from '@mui/material/Button';
// import i1 from "../../assets/images/i1.jpeg";
// import main from "../../assets/images/main.png";
// import b1 from "../../assets/images/b1.webp";
import Container from "@mui/material/Container";
// import {
//   Img,
//   ButtonWrapper,
//   ArrowForward,
//   ArrowRight,
// } from "../../components/herosection/elements.jsx";

// import rrp from "../../assets/images/i1.jpeg";
// import goodluck from "../../assets/images/Disclaimer.png";
import TextField from "@mui/material/TextField";
import Endpage from "../MainPage/MainPageContent/EndPage.js";
import hbf from "../../assets/images/hbf1.png";
import incomenet from "../../assets/images/incomenet1.png";
import taiki from "../../assets/images/taiki1.png";
import webyne from "../../assets/images/web.png";
import danier from "../../assets/images/dl.png";
import relations from "../../assets/images/realtions.png";
import axios from "axios";
import {api,imageApi} from "../../components/Api/Api.jsx";
import ShareCard from "../../components/cards/ShareCard.jsx";

// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


export default function Unlisted({idpoly,headings,images,url}) {

  const [data1,setData1] = useState([])
  const [search,setSearch] = useState("")

  const fetchData = async ()=>{
    try{
    await fetch(api)
    .then(res => res.json())
    .then(res => setData1(res.detail.rows.slice(-9)))
    }
    catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchData()
  },[])
  // const Element = Scroll.Element;
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => {
  //     setIsOpen(!isOpen)
  // const navigate = useNavigate();
  // const [hover, setHover] = useState(false);
  // const onHover = () => {
  //   setHover(!hover);
  // };
  // }

  // const data = [
  //   {
  //     name: "HBF Private Equity",
  //     image: hbf,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "Incomenet Private Equity",
  //     image: incomenet,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "Taiki Private Equity",
  //     image: taiki,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "webyne Private Equity",
  //     image: webyne,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "Danier Private Equity",
  //     image: danier,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "RelationsAI Private Equity",
  //     image: relations,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "HBF Private Equity",
  //     image: hbf,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  //   {
  //     name: "Incomenet Private Equity",
  //     image: incomenet,
  //     price: "₹ 900",
  //     share: "(+0) (0%)",
  //   },
  // ];

  const handleChange = (e)=>{
    setSearch(e.target.value)
}

const filter = data1 !== null ? data1.filter(f=>(f.name).toLowerCase().includes(search.toLowerCase())) : []


  return (
    <>
      <Box>
        {/* <Container> */}
        <Navbar />
        <Container>
          <Typography
            sx={{ textAlign: "center", margin: "4rem 0rem 0rem 0rem" }}
          >
            <h1 style={{ fontSize: "2.5rem", fontFamily: "Daikon-regular" }}>
              {" "}
              Limited Partnerships
            </h1>
          </Typography>
          <Typography sx={{ fontSize: "larger", fontFamily: "Daikon-regular",textAlign:"center" }}>
          Explore the Businesses  that we have thoroughly researched for our investor clients. Our curated list of limited partnerships represents promising opportunities for those seeking to diversify their portfolios and achieve long-term growth. At AQT Direct, we guide investors ahead, ensuring they make informed decisions in a competitive market landscape.{" "}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button
                variant="contained"
                color="success"
                sx={{ backgroundColor: "#35a848" }}
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contactus"
                >
                  Sign In Here
                </Link>
              </Button>
            </Box>
            {/* <img src={main} alt={''} style={{width:"30rem",margin:"2rem 0rem 1rem 18rem"}}/> */}
          </Box>
        </Container>

        {/* <Box
          sx={{ textAlign: "center", padding: "12px", paddingBottom: "28px" }}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={handleChange}
            sx={{ width: "40rem" }}
          />
        </Box> */}

        <Box sx={{ marginLeft: "6%", marginRight: "6%",marginTop:"3%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid container spacing={2}>
                            {/* {filter !== null ? filter.map((company) => (
                                <Grid item xs={4} key={company.id}>
                                    <ShareCard imageUrl={`${imageApi}${company.cmpImg}`} onClick={()=>
                                    {headings(company.name)
                                    idpoly(company.id)
                                    images(`${imageApi}${company.cmpImg}`)}} 
                                    name={company.name} ipoYear={company.ipoYear} url={company.websiteUrl} />
                                </Grid>
                            )): ""} */}
                             {dataShow !== null ? dataShow.map((company) => (
                                <Grid item xs={4} key={company.id}>
                                    <ShareCard imageUrl={company.imageUrl} 
                                    // onClick={()=>
                                    // {headings(company.name)
                                    // idpoly(company.id)
                                    // images(`${imageApi}${company.cmpImg}`)}} 
                                    name={company.name} ipoYear={company.ipoYear} 
                                    url={company.websiteUrl} />
                                </Grid>
                            )): ""}
                            
                        </Grid>
          </Grid>
        </Box>
        <Box sx={{ textAlign: "center", padding: "20px" }}>
          <Button
            variant="contained"
            color="success"
            sx={{ backgroundColor: "#35a848" }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/companylist"
            >
              View More
            </Link>
          </Button>
        </Box>
        {/* </Container> */}

        {/* <Endpage /> */}
        <Footer />
      </Box>
    </>
  );
}
