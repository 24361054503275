import * as React from 'react';
import Navbar from '../../../components/navbar/index';
import Footer from '../../../components/Footer/Footer';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Link, useLocation } from 'react-router-dom';
import { useState,useEffect } from 'react';
import ShareCard from "../../../components/cards/ShareCard"
import {api,imageApi} from "../../../components/Api/Api";
import {dataShow} from "../DataMain";


// const Img = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   maxWidth: '100%',
//   maxHeight: '100%',
// });

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

// const data = [
//   {
//     name: "HBF Private Equity",
//     image: hbf,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Incomenet Private Equity",
//     image: incomenet,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Taiki Private Equity",
//     image: taiki,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "webyne Private Equity",
//     image: webyne,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Danier Private Equity",
//     image: danier,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "RelationsAI Private Equity",
//     image: relations,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "HBF Private Equity",
//     image: hbf,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Incomenet Private Equity",
//     image: incomenet,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Taiki Private Equity",
//     image: taiki,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "webyne Private Equity",
//     image: webyne,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Danier Private Equity",
//     image: danier,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "RelationsAI Private Equity",
//     image: relations,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "HBF Private Equity",
//     image: hbf,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Incomenet Private Equity",
//     image: incomenet,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Taiki Private Equity",
//     image: taiki,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "webyne Private Equity",
//     image: webyne,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Danier Private Equity",
//     image: danier,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "RelationsAI Private Equity",
//     image: relations,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "HBF Private Equity",
//     image: hbf,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Incomenet Private Equity",
//     image: incomenet,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Taiki Private Equity",
//     image: taiki,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "webyne Private Equity",
//     image: webyne,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Danier Private Equity",
//     image: danier,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "RelationsAI Private Equity",
//     image: relations,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "HBF Private Equity",
//     image: hbf,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Incomenet Private Equity",
//     image: incomenet,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Taiki Private Equity",
//     image: taiki,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "webyne Private Equity",
//     image: webyne,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "Danier Private Equity",
//     image: danier,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },
//   {
//     name: "RelationsAI Private Equity",
//     image: relations,
//     price: "₹ 900",
//     share: "(+0) (0%)",
//   },

// ]

export default function CardViewMore({idpoly,headings}) {

  const [data,setData] = useState([])
  const [search,setSearch] = useState("")

  const FetchData = async ()=>{
    await fetch(api)
    .then(res => res.json())
    .then(res => setData(res.detail.rows))

    }

    const handleChange = (e)=>{
      setSearch(e.target.value)
    }

    const filter = data !== null ? data.filter(f=>(f.name).toLowerCase().includes(search.toLowerCase())) : []

  useEffect(()=>{
    FetchData()
  },[])

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <Box sx={{ textAlign: 'center', paddingBottom: '1rem', paddingTop: '2.5rem' }}>
        <TextField value={search} onChange={handleChange} id="outlined-basic" label="Search" variant="outlined" sx={{ width: 600 }} />
        {/* <Button variant="contained" sx={{ margin: "0.5rem 0rem 0rem 0.5rem", backgroundColor: "green" }} color='success'>Search</Button> */}
      </Box>


      <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: '6%', marginRight: '6%', padding: '2rem' }}>
        <Grid container spacing={0}
          rowSpacing={1.2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* {filter !== null ? filter.map((company) => (
                                <Grid item xs={4} key={company.id}>
                                    <ShareCard imageUrl={`${imageApi}${company.cmpImg}`} onClick={()=>
                                    {headings(company.name)
                                    idpoly(company.id)}} 
                                    name={company.name} ipoYear={company.ipoYear}
                                    url={company.websiteUrl} />
                                </Grid>
                            )): ""} */}

{dataShow !== null ? dataShow.map((company) => (
                                <Grid item xs={4} key={company.id}>
                                    <ShareCard imageUrl={company.imageUrl} 
                                    // onClick={()=>
                                    // {headings(company.name)
                                    // idpoly(company.id)}} 
                                    name={company.name} ipoYear={company.ipoYear}
                                    url={company.websiteUrl} />
                                </Grid>
                            )): ""}
          {/* {data.map((stock) => (
            <Grid item xs={2.85} sx={{ margin: "0rem 0rem 1.5rem 0.5rem" }}>
              <Item
                sx={{
                  border: "1px solid darkgray",
                  backgroundColor: "#F4F9F9",
                  width:300,
                  margin:"0rem 0.5rem 0rem 0.5rem",
                  "&:hover": {
                    boxShadow: "4px 4px darkgray",
                    "z-index": 0.1,
                  },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <img
                    style={{ width: 100,height:80,backgroundColor : "black" }}
                    src={`http://192.168.1.32:8080/api/V1/user/download/?title=${stock.cmpImg}`}
                    alt={stock.cmpImg}

                  />
                  <Box sx={{ display: "flex", flexDirection: "column", paddingTop:'1rem', textAlign:'initial', paddingLeft:'4px',width:200,height:60 }}>
                    <Link
                      style={{ textDecoration: "none", color: "#757474" }}
                      to="/polymatech"
                    >
                      <Typography
                        sx={{
                          "&:hover": {
                            color: "green",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {stock.name}{" "}
                      </Typography>
                    </Link>
                    <Typography>
                      {stock.ipoYear} <br />
                      {stock.equity}
                    </Typography>
                  </Box>
                </Box>
              </Item>
            </Grid>
          ))} */}
        </Grid>
        {/* <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            p: 2,
            margin: '1rem 1rem 1rem 2rem',
            maxWidth: 325,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase sx={{ width: 100, height: 100 }}>
                <Img alt="complex" src={hbf} sx={{ backgroundColor: "blue" }} />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" sx={{ '&:hover': { color: 'green', cursor: "pointer" } }} component="div">
                    HBF India Private Limited
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Shares <br />&#8377;4800
                  </Typography>

                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', '&:hover': { color: 'green' }, fontWeight: 600 }} variant="body2">
                    (+0.00)(0%) 15D
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper> */}
      </Box>
      <Footer />

    </>
  )
}