import React from "react";
import Navbar from "../../components/navbar/index";
import Footer from "../../components/Footer/Footer";
import aboutus from "../../assets/images/aboutus.jpg";
import Container from '@mui/material/Container';
import mission from "../../assets/images/mission.jpg";
import vision from "../../assets/images/vision1.png";
import ceo from "../../assets/images/ceo.png";
import dummy from "../../assets/images/dummyuser.png";
// import aboutus1 from "../../assets/images/aboutus1.jpg";
import aboutus2 from "../../assets/images/aboutus2.jpeg";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function AboutUs(){
    const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    return(
        <>
        <Navbar />
        <Box sx={{padding:"2rem 0rem 0rem 0rem"}}>
            <img style={{width: '100%',justifyContent:"center",margin: "auto",
    display: "block",height:'20rem'}} src={aboutus2} alt="aboutus"/>
        </Box>
        <Typography variant="h5" sx={{textAlign:"center",fontWeight:600,margin:"2rem 0rem 2rem 0rem",fontFamily: "Daikon-regular"}}>AQT Direct Limited is a leading consultancy firm specializing in private equity investments.</Typography>
        <Box sx={{margin:"0.5rem 6rem 0.5rem 6rem",textAlign:"left",fontFamily: "Daikon-regular"}}>
        <Typography variant="p" sx={{fontFamily: "Daikon-regular"}} >At AQT, we're not just a Private Equity Consultancy firm; we're architects of opportunity and architects of change. With a deep-rooted commitment to excellence and innovation, we specialize in reshaping the private equity landscape through strategic insights, dynamic partnerships, and sustainable growth initiatives.
        <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Driven by a passion for progress and a vision for a brighter tomorrow, we're dedicated to fueling the evolution of India's business ecosystem. From pioneering investment models to fostering impactful change, we're shaping the future of private equity in India and beyond.<br/>
        With a focus on integrity, collaboration, and unwavering dedication, we're not just shaping businesses; we're shaping legacies.<br/> Welcome to AQT, where opportunity meets transformation, and the possibilities are limitless.</Typography>
        </Box>
        <Box sx={{display:"flex",margin:'0rem 3rem 0rem 3rem'}}>
        <Box ><Typography variant="h5" sx={{margin:"3rem 0rem 0rem 15rem",fontWeight:600,fontFamily: "Daikon-regular"}}>Our Mission</Typography>
        <Box sx={{display:"flex",alignItems:"flex-start",padding:"1rem 5rem 1rem 5rem"}}>
       
            <Typography variant="p" sx={{fontWeight:400,textAlign:'left',margin:"1.5rem 0.5rem 0rem 1rem",fontFamily: "Daikon-regular"}}> <img src={mission} alt="mission" style={{width:'11rem',height:'6rem',objectFit: 'contain',float:"left"}}/> AQT Direct Limited is on a mission to enhance financial literacy among the Indian population. We aim to enlighten individuals about the short-term and long-term benefits of smart investments, nurturing them into angel investors. By funding businesses in need of growth capital, we aspire to create generational wealth and foster economic prosperity.</Typography>
        </Box>
        
        </Box>
        <Box ><Typography variant="h5" sx={{margin:"3rem 0rem 0rem 15rem",fontWeight:600,fontFamily: "Daikon-regular"}}>Our Vision</Typography>
        <Box sx={{display:"flex",alignItems:"flex-start",padding:"1rem 5rem 1rem 5rem"}}>
       
            <Typography variant="p" sx={{fontWeight:400,textAlign:'left',margin:"1.5rem 0.5rem 0rem 1rem",fontFamily: "Daikon-regular"}}> <img src={vision} alt="mission" style={{width:'11rem',height:'6rem',objectFit: 'contain',float:"left",padding:"0.5rem"}}/> Our vision is to cultivate a supportive ecosystem where trust and capital flow freely, empowering people to grow together. We envision a thriving India, with businesses expanding globally, bolstering the economy. This transformation can only occur by fostering a collaborative ecosystem where awareness and support pave the way for sustainable growth and prosperity.</Typography>
        </Box>
        
        </Box>
        {/* <Box><Typography variant="h5" sx={{margin:"3rem 0rem 0rem 15rem",fontWeight:600,fontFamily: "Daikon-regular"}}>Our Vision</Typography>
        <Box sx={{display:"flex",padding:"1rem 5rem 1rem 5rem", alignItems: 'flex-start'}}>
        
            <Typography variant="p" sx={{fontWeight:400,textAlign:'left',margin:"1rem 0.5rem 0rem 1rem",fontFamily: "Daikon-regular"}}><img src={vision} alt="vision" style={{width:'11rem',height:'9rem',objectFit: 'contain',float:"left"}}/> Our vision is to cultivate a supportive ecosystem where trust and capital flow freely, empowering people to grow together. We envision a thriving India, with businesses expanding globally, bolstering the economy. This transformation can only occur by fostering a collaborative ecosystem where awareness and support pave the way for sustainable growth and prosperity.</Typography>
            
        </Box>
        </Box> */}
        {/* <Box><Typography variant="h5" sx={{margin:"3rem 0rem 0rem 15rem",fontWeight:600,fontFamily: "Daikon-regular"}}>Our Vision</Typography>
        <Box sx={{ display: "flex", padding: "1rem 5rem", alignItems: 'flex-start' }}>
      <img 
        src={vision} 
        alt="mission" 
        style={{ width: '11rem', height: '9rem', objectFit: 'contain', marginRight: '1rem' }}
      />
      <Typography 
        variant="body1" 
        sx={{ 
          fontWeight: 400, 
          textAlign: 'left', 
          fontFamily: "Daikon-regular",
          flex: 1
        }}
      >
        Our vision is to cultivate a supportive ecosystem where trust and capital flow freely, empowering people to grow together. We envision a thriving India, with businesses expanding globally, bolstering the economy. This transformation can only occur by fostering a collaborative ecosystem where awareness and support pave the way for sustainable growth and prosperity.
      </Typography>
    </Box>
    </Box> */}
        </Box>
        <Box sx={{display:"flex",padding:"2rem"}}>
        <Box sx={{display:"flex",flexDirection: 'column',padding:"1rem"}}>
                <Typography sx={{fontWeight:800,margin:"0rem 0rem 0.5rem 13rem",fontFamily: "Daikon-regular"}}>CEO Message</Typography>
            <img src={ceo} alt="mission" style={{width:'15rem',padding:"0rem 0rem 0rem 10rem"}}/>
            <Typography variant="p" sx={{textAlign:'left',padding:"0.5rem 5rem 0.5rem 5rem",fontFamily: "Daikon-regular"}}>By backing and nurturing these small enterprises, we have the power to generate employment, encourage creativity, and stimulate economic advancement within our local areas. Additionally, this endeavour represents a significant stride towards India's ambition of ranking among the globe's top three economies. As citizens, it's incumbent upon us to contribute to our nation's progress and prosperity.</Typography>
        </Box>
        <Box sx={{display:"flex",flexDirection: 'column',padding:"1rem"}}>
        <Typography sx={{fontWeight:800,padding:"0rem 0rem 0.5rem 11rem",fontFamily: "Daikon-regular"}}>CBO Message</Typography>
            <img src={dummy} alt="mission" style={{width:'9rem',padding:"0rem 0rem 0rem 11rem"}}/>
            <Typography variant="p" sx={{textAlign:'left',padding:"0.5rem 3rem 0.5rem 3rem",fontFamily: "Daikon-regular"}}>I am dedicated to transforming the private equity landscape through the cultivation of strategic alliances, inventive investment frameworks, and sustainable development endeavours. My vision is to instigate meaningful progress, catalyzing the metamorphosis of India's business terrain, and positioning AQT as a driving force for beneficial economic change.</Typography>
        </Box>
        </Box>
        <Footer />
        </>
    )
}