import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import refresh from './refresh.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import IT from '../../../assets/images/IT.jpg'
import Finance from '../../../assets/images/Finance.jpg'
import Health from '../../../assets/images/Health.jpg'
import Sales from '../../../assets/images/Sales.jpg'
import Media from '../../../assets/images/Media.jpg'
import Marketing from '../../../assets/images/Marketing.jpg'
import Stocks from '../../../assets/images/Stocks.jpg'
import Data from '../../../assets/images/Data.jpg'
import Buisness from '../../../assets/images/Buisness.jpg'
import Agriculture from '../../../assets/images/Agriculture.png'
import '../MainBody.css'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function BodyInside() {
  return (
    <>

      <Box sx={{ width: '100%', height: "100%", 'backgroundColor': "#EEF5F9", "overflow": "hidden", fontFamily: "Daikon-regular", textAlign: 'center' }}>
        <Typography sx={{ textAlign: "center", fontFamily: 'Daikon-regular' }}></Typography>
          <h1>Top Sectors In Private Equity Market</h1>
          <Typography className='owlBox'>At AQT Direct, we simplify private equity, offering expert guidance to navigate limited partnerships in promising businesses. Our services include research and audit, equity deal compliances, value creation, exit strategies, and much more. Explore how AQT Direct can help you achieve your investment goals today.
        </Typography>
        <OwlCarousel className='owl-theme' loop='true' autoplay='true' margin={-160} autoplayTimeout='3000' items={5} nav style={{ textAlign: 'center' }}>
          <div className='item' >
            <h4>
              <img className='carouselDiv' src={Agriculture} alt='agri' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer" } }}>Agriculture</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={IT} alt='it' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer"} }}>IT</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Finance} alt='finance' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer" } }}>Finance</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Health} alt='health' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer"} }}>Health</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Sales} alt='sales' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer" } }}>Sales</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Media} alt='media' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer" } }}>Media</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Marketing} alt='market' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer"} }}>Marketing</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Stocks} alt='stocks' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer"} }}>Stocks</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Data} alt='data' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer"} }}>Data</Typography>
            </h4>
          </div>
          <div className='item'>
            <h4>
              <img className='carouselDiv' src={Buisness} alt='business' style={{ width: '30vh', margin: 'auto' }} />
              <Typography sx={{ marginTop: '24px', '&:hover': { color: "green", cursor: "pointer"} }}>Buisness</Typography>
            </h4>
          </div>
        </OwlCarousel>
        <div style={{ paddingBottom: '20px', marginTop: '18px' }}>
          {/* <Button variant="contained" color='success' sx={{ backgroundColor: "#35a848" }}><Link style={{ textDecoration: 'none', color: 'white' }} to='/topsectors'>View More</Link></Button> */}
        </div>
      </Box>
    </>
  );
}