import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import disclaimer from '../../assets/images/Disclaimer.png';
import disclaimer2 from '../../assets/images/disclaimer2.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Navbar from '../../components/navbar/index';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Disclaimer(){
    const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return(
        <>
        <Navbar/>
        <Box sx={{margin:"1rem 1rem 1rem 1rem"}}>
        <Typography variant='h3' sx={{margin:"0rem 0rem 0rem 35rem",fontWeight: 600}}> Disclaimer</Typography>
        <Typography sx={{fontSize:"large",margin:"0rem 0rem 0rem 10rem"}}>AQT Direct  is not a stock exchange or a trading platform recognized by the Securities Exchange Board of India (SEBI) under the</Typography><Typography sx={{fontSize:"large",margin: "0rem 0rem 0rem 8.9rem"}}> Securities Contract (Regulation) Act, 1956. Instead, AQT Direct  serves as a specialized online platform designed to facilitate liquidity </Typography> <Typography sx={{fontSize:"large",margin:"0rem 0rem 0rem 12rem"}}> by enabling the buying and selling of Private Equity in Pre-IPO companies and startups within India.</Typography>
        </Box>
        <img style={{width:"20rem",margin:"0rem 0rem 0rem 32rem"}} src={disclaimer}/>
        <img style={{maxWidth:"97.5vw",margin:"1rem 0.5rem 1rem 0.5rem"}} src={disclaimer2}/>
        <Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 0rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
            <Box sx={{display:"flex",margin:"1rem 1rem 1rem 1rem"}}>
            <CheckCircleIcon sx={{color: "green",width:"1.2rem"}}/>
            Information that you provide to us by filling in forms on the website. This includes both personal information and Sensitive Personal Information including contact information, name, gender, email address, mailing address, phone number, financial information including bank account details, payment instrument details, and terms thereof, if any, unique identifiers including user name, account number, password and personal preferences including favourites lists, transaction history and investment preference.
            </Box>
        </Box>
        <Footer/>
        </>
    )
}