import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Accordialeft() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box sx={{ fontFamily:'Daikon-regular' , paddingBottom:'2rem'}}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }} >Differences between pre-IPO and post-IPO investing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              Pre-IPO and post-IPO investing are both ways to invest in a company, but they differ in several key ways. Pre-IPO investing refers to buying shares in a company before it goes public. These shares are typically offered to a select group of investors, such as venture capitalists and angel investors. Because the company is not yet public, there is less information available about the company and its financials, which can make pre-IPO investing more speculative and risky.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }}>How to evaluate the management team and leadership of a pre-IPO company.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              From vision to execution: How to evaluate the management team's strategy of a pre-IPO company When evaluating a pre-IPO company, it's important to assess the quality of its management team and leadership. A strong management team can be a key indicator of a company's potential for success, while a weak management team can be a red flag.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }}>How to navigate the process of buying shares in a pre-IPO company.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              From research to execution: A step-by-step guide to buying shares in a pre-IPO company.<br />

              Navigating the process of buying shares in a pre-IPO company can be complex and involves several steps. Here is an overview of the process:<br />


              1. Research the company: Before investing in a pre-IPO company, it's important to conduct thorough research on the company. This includes researching the company's financials, management team, and market opportunity. Additionally, it's important to check if the company has a viable product or service, a strong management team, and a clear path to profitability.<br />

              2. Identify the investment opportunity: Pre-IPO companies are not publicly traded, so the investment opportunities can be limited. It's important to identify the investment opportunity, whether it's through a private placement, a direct investment, or an investment fund.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }}>Collapsible Group Item #4</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }}>Collapsible Group Item #5</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }}>Collapsible Group Item #6</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
            <Typography sx={{ fontFamily:'Daikon-regular' }}>Collapsible Group Item #7</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily:'Daikon-regular' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
