import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import privacy from '../../assets/images/Privacy.png';
import privacy2 from '../../assets/images/privacy2.png';
import Rightarrow from '../../assets/images/rightarrow.png';
import Navbar from '../../components/navbar/index';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Privacy(){
    const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return(
        <>
        <Navbar/>
        <Typography variant='h3' sx={{margin:"2rem 0rem 0rem 30rem",fontWeight: 500}}>Privacy Policy</Typography>
        <Typography variant='h6' sx={{margin:"0rem 0rem 0rem 26rem"}}>Please check the details on this page carefully</Typography>
        <img src={privacy} alt='images' style={{width:"20rem",margin:"2rem 0rem 4rem 30rem"}}/>
        <img src={privacy2} alt='images' style={{width:"83.3rem",margin:"1rem 0.5rem 2rem 0.5rem"}}/>
        <Box sx={{margin:"1rem 2rem 1rem 2rem",textAlign:"justify"}}>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>We at AQTDirect.com run our business from IZUZ Consultancy Pvt. Limited. and Investor Zone and Unlisted Zone partnership firm, having its registered office at A-162, New Panchwaty Colony, Ghaziabad, Uttar Pradesh- 201001 and 232/12, New Kot Gaon, Near Shiv Mandir, Ghaziabad, Uttar Pradesh-201001, respectively which expression shall mean and include its affiliates, successors and permitted assigns) and provides certain services on www.unlistedzonecom (Website). These Website is owned and operated by IZUZ Consultancy Pvt. Limited and InvestorZone and UnlistedZone, partnership firm.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>1ZUZ Consultancy Pt. Limited and Investor Zone and Unlisted Zone, partnership firm, view protection
                    of registered users’ data privacy as a very important principal.</Typography>
                    <Box>
            <Box sx={{display:"flex"}}>
                <img style={{width:"1.5rem"}} src={Rightarrow} alt='rightarrow'/>
                <Typography sx={{fontSize:"larger",fontWeight:600,margin:"0rem 0rem 0rem 0.3rem"}}>Purpose :</Typography>
            </Box>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>This privacy policy (policy) constitutes a legal agreement between You (You or Your, User), as the user of the Website, and, IZUZ Consultancy Pvt. Limited and Investor Zone and Unlisted Zone partnership firm, as the owner of the Website. This Policy is only applicable to the Users of the website, and the information and data gathered from the Users directly and not to any other information or website. You are hereby advised to read this Policy carefully and fully understand the nature and purpose of gathering and/or collecting sensitive, personal and other information and the usage, disclosure and sharing of such information.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>This Policy has been prepared under the provisions of (Indian) Information Technology Act, 2000 (ITAct) and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (IT Rules) promulgated thereunder, and sets out the practices and policies for the protection of personal information (including sensitive personal data or information) collected, received, possessed, stored, dealt with or handled by 1ZUZ Consultancy Pvt. Limited and Investor Zone and Unlisted Zone partnership firm and its website UnlistedZone.com.</Typography>
            </Box>
            <Box>
        <Box sx={{display:"flex"}}>
                <img style={{width:"1.5rem"}} src={Rightarrow} alt="Rightarrow1"/>
                <Typography sx={{fontSize:"larger",fontWeight:600,margin:"0rem 0rem 0rem 0.3rem"}}>Eligibility :</Typography>
            </Box>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>
            This privacy policy (Policy) describes our policies and procedures on the collection, use, storage and disclosure of any information including, business or personal information provided by you while using our website. This Policy specifically governs: the collection and use of personal data and sensitive personal data or information provided by You. The processing of personal information and sensitive personal data or information provided by you while using our website.
            </Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>Your use of the website will be governed by this Policy in addition to the Terms of Use as applicable to you. This Policy together with the Terms of Use are referred to as the website documents.</Typography>
        </Box>
        <Box>
        <Box sx={{display:"flex"}}>
                <img style={{width:"1.5rem"}} src={Rightarrow} alt="Rightarrow12"/>
                <Typography sx={{fontSize:"larger",fontWeight:600,margin:"0rem 0rem 0rem 0.3rem"}}>Collection Of Information :</Typography>
            </Box>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>1ZUZ Consultancy Pt. Limited and Investor Zone and Unlisted Zone, a partnership firm will use the User Information provided by you only in accordance with the purposes described in this Policy.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>You can access the website only after registering for an account. You may not be able to access website or features provided on the website that require registration, you to reveal your identity andor other Personal Information or Sensitive Personal Information about you.</Typography>
            <Typography sx={{margin:"1rem 0rem 1rem 0rem"}}>During Your use of our website, we may collect and process such information from you, including but not limited to the below mentioned:</Typography>
        </Box>
        </Box>
        <Footer/>
        </>
    )
}