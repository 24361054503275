import React,{useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import hbf from '../../../assets/images/hbf1.png';
import {api,imageApi} from "../../../components/Api/Api";
import ShareCard from '../../../components/cards/ShareCard';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// const data = [
//   {
//     imageUrl: hbf,
//     name: "HBF Direct Limited ",
//     price: '4800'
//   },
//   {
//     imageUrl: incomenet,
//     name: "Incomet Learning Ltd ",
//     price: '4800'
//   },
//   {
//     imageUrl: taiki,
//     name: "Taiiki Media Ltd ",
//     price: '4800'
//   },
//   {
//     imageUrl: webyne,
//     name: "Webyne Data Center ",
//     price: '4800'
//   },
//   {
//     imageUrl: danier,
//     name: "Danier Leather ",
//     price: '4800'
//   },
//   {
//     imageUrl: relations,
//     name: "Relations AI",
//     price: '4800'
//   },
// ]

export default function BodyTwo({idpoly,headings}) {
  const [data,setData] = useState([])

  const FetchData = async ()=>{
    await fetch(api)
    .then(res => res.json())
    .then(res => setData(res.detail.rows))
    }
    useEffect(()=>{
    FetchData()
    },[])

  return (
    <>
      <Container fixed sx={{ paddingBottom: '20px' }}>
        <Box sx={{ fontFamily: 'Daikon-regular', padding: '10px' }} >
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <Typography style={{ textAlign: "center" }}>
                <h1 style={{ fontFamily: 'Daikon-regular' }}>New Arrivals</h1>
              </Typography>
            </Grid>
            <Grid xs={12} style={{ textAlign: 'center' }}>
              <Typography><p style={{ fontFamily: 'Daikon-regular' }}>Discover The latest Arrivals In Private Equity and Elevate YOUR Investment Portfolio with AQT Direct Today.</p></Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginLeft: '25px', marginRight: '-30px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
            {/* {
              data.map((stock) => (
                <Grid item xs={3.7} sx={{ margin: "0rem 0rem 1.5rem 0.5rem" }}>
                  <Item sx={{ "border": "1px solid darkgray", backgroundColor: '#F4F9F9', '&:hover': { boxShadow: "4px 4px darkgray", "z-index": 0.1 } }}>
                    <Box sx={{ display: "flex" }}><img style={{ width: 100,backgroundColor:"black" }} src={`${imageApi}${stock.cmpImg}`} alt={stock.id} />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Link style={{ textDecoration: "none", color: '#757474', paddingTop:'2rem', paddingLeft:'2rem', fontFamily:'Daikon-regular'}} to='/polymatech'><Typography sx={{fontWeight:600,
                          '&:hover': {
                            color: "green", cursor: "pointer"
                          }
                        }}>{stock.name} </Typography></Link>
                        <Typography sx={{textAlign:"center"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IPO Year : {stock.ipoYear} <br />{stock.equity}</Typography></Box></Box></Item>
                </Grid>
              ))
            } */}
             {data !== null ? data.map((company) => (
                                <Grid item xs={6} key={company.id}>
                                    <ShareCard imageUrl={`${imageApi}${company.cmpImg}`} onClick={()=>
                                    {headings(company.name)
                                    idpoly(company.id)}} 
                                    name={company.name} ipoYear={company.ipoYear} />
                                </Grid>
                            )): ""}
          </Grid>
        </Box>
      </Container>

    </>
  );
}
