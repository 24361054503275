import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import Button from '@mui/material/Button';
import './MainBody.css';
import speaker from './MainPageContent/speaker.png';
import Typography from '@mui/material/Typography';
import BodyInside from './MainPageContent/BodyInside';
import BodyTwo from './MainPageContent/BodyTwo';
import EndPage from './MainPageContent/EndPage';
import TablePage from './MainPageContent/Table';
import Testimonial from './MainPageContent/Testimonial';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import Container from '@mui/material/Container';
import ShareCard from "../../components/cards/ShareCard.jsx";
import Grid from '@mui/material/Unstable_Grid2';
import main from '../../assets/images/main-trade.png'
import bulb from '../../assets/images/bull-bulb-line.png'
import { useState } from "react";
import {api,imageApi} from "../../components/Api/Api.jsx";
import {redirect} from "../../components/Api/Api.jsx";
import {dataShow} from "./DataMain.js";

const cardPerPage = 6;
export default function MainBody({idpoly,headings,images,url}) {
    const [data,setData] = useState(null);
    const [dataOnPage,setDataOnPage] = useState(null);
    const [search,setSearch] = useState("");
    const [pages,setPages] = useState(1);
    const lastIndex = cardPerPage * pages;
    const firstIndex = lastIndex - cardPerPage;


    const styles = {
        containermain: {
            width: '100%',
            height: '80vh',
            backgroundImage: `url(${bulb})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
    };

    // const companies = [
    //     {
    //         imageUrl: hbf,
    //         name: "HBF Direct Limited ",
    //         price: '4800'
    //     },
    //     {
    //         imageUrl: incomenet,
    //         name: "Incomet Learning Ltd ",
    //         price: '4800'
    //     },
    //     {
    //         imageUrl: taiki,
    //         name: "Taiiki Media Ltd ",
    //         price: '4800'
    //     },
    //     {
    //         imageUrl: webyne,
    //         name: "Webyne Data Center ",
    //         price: '4800'
    //     },
    //     {
    //         imageUrl: danier,
    //         name: "Danier Leather ",
    //         price: '4800'
    //     },
    //     {
    //         imageUrl: relations,
    //         name: "Relations AI",
    //         price: '4800'
    //     },
    // ]

    const FetchData = async ()=>{
        await fetch(api)
        .then(res => res.json())
        .then(res => 
            setData(res.detail.rows.slice(-8))
        )
    }

    useEffect(()=>{
        FetchData()
    },[])

    const handleChange = (e)=>{
        setSearch(e.target.value)
    }

    const filter = data !== null ? data.filter(f=>(f.name).toLowerCase().includes(search.toLowerCase())) : []

    // const redir = function redirectToPloy(id){
    //     navigate('/polymatech',{state:id})
    // }

    return (
        <>
            <Navbar />
            <div style={styles.containermain}>
                <h1 className="head">Managing & Maximizing Private Equity  </h1>
                <p className="para">Welcome to AQT Direct Limited, your gateway to managing and maximizing private equity investments. We specialize in building fortunes and creating legacies for our clients, while safeguarding them from valuation bubbles.  </p>
                <div style={{ textAlign: 'center', paddingTop: '12px', paddingBottom: '' }}>
                    <Link to='/about'>
                    <Button variant="contained" color='success' sx={{ backgroundColor: "#35a848", padding: '12px', width: '140px' }} ><span style={{ textDecoration: 'none', color: 'white' }} >View More</span></Button></Link>
                </div>
                <div >
                    <div className="image-div">
                        <img className="imagemain" alt="name" src={main}  />
                    </div>

                </div>
            </div>


            {/* <div className="SearchbarMain"> */}
            <Container >
                <div className="searchbar">
                    {/* <img className="image" src={search} alt="searching"></img> */}
                    <input className='inputmain' type='text' placeholder='Search...'
                    value={search} onChange={handleChange}
                    /><SearchIcon sx={{ margin: "0.7rem 0rem 0rem -2rem" }} />

                    {/* <button className='buttonNav' type='button'>Search</button> */}
                </div>
                <div className='boxes'>
                    <div className="boxes">
                        <Grid container spacing={2}>
                            {/* {filter !== null ? filter.map((company) => (
                                <Grid item="true" xs={6} key={company.id}>
                                    <ShareCard imageUrl={`${imageApi}${company.cmpImg}`} onClick={()=>
                                    {
                                    headings(company.name)
                                    idpoly(company.id)
                                    images(`${imageApi}${company.cmpImg}`)
                                    
                                }
                                } 
                                    name={company.name} ipoYear={company.ipoYear}     url={company.websiteUrl} />
                                </Grid>
                            )): ""} */}
                             {dataShow !== null ? dataShow.map((company) => (
                                <Grid item="true" xs={6} key={company.id}>
                                    <ShareCard imageUrl={company.imageUrl} 
                                //     onClick={()=>
                                //     {
                                //     headings(company.name)
                                //     idpoly(company.id)
                                //     images(`${imageApi}${company.cmpImg}`)
                                    
                                // }
                                // } 
                                    name={company.name} ipoYear={company.ipoYear}     url={company.websiteUrl} />
                                </Grid>
                            )): ""}
                        </Grid>
                    </div>

                    <div className='boxes2'>
                        <div className='boxes21'>
                            <h1>Attention Required</h1>
                            <div>
                                <p>Create Your Own Account and Begin Your <br /> Investment Journey In Private Equity With <br /> AQT Direct.</p>
                            </div>
                            <div className='buttmycard'>
                                <Link className="buttonmain1" to={redirect}>   <button className='butmycard' type='button' >Complete Setup</button></Link>
                                <img src={speaker} alt='speaker' />
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '25px' }}>
                    <Button variant="contained" color='success' sx={{ backgroundColor: "#35a848" }}><Link style={{ textDecoration: 'none', color: 'white' }} to='/companylist'>View More</Link></Button>

                </div>

            </Container>

            {/* </div> */}
            {/* <Graph/> */}
            <BodyInside />
            {/* <BodyTwo  headings={headings} idpoly={idpoly}/> */}
            {/* <TablePage /> */}
            {/* <div style={{ height: "30rem", padding: '20px' }}>
                <div style={{ textAlign: 'center', fontFamily: "Daikon-regular" }}>
                    <h1 >Process To Buy Private Equity</h1>
                    <p >Recommended For Those Interested In Dealing With Private Equity</p>
                </div>
                <div style={{ "display": "flex", "margin": "5em 0em 0em 3em", "justifyContent": "space-evenly" }}>
                    <div style={{ "display": "flex", "height": "0em", "width": "23em", "marginLeft": "4em" }}>
                        <div style={{ "margin": "1em 2em 0em 0em", "padding": "0.2em 1em 0em 0em" }}>
                            <div style={{ "backgroundColor": "green", "color": "white", height: "2rem", width: " 2.5rem", "borderRadius": "51px", "textAlign": "center", "padding": "0.5rem 0rem 0rem 0rem", "marginLeft": "3em" }}>1</div>
                            <div style={{ "fontSize": "xxLarge", "fontWeight": "600", margin: "1rem 0rem 0rem 2rem" }}>Contact Us</div>
                            <div style={{ padding: "1rem 0rem 0rem 0rem" }}>Connect With Our RMs and Get Best Unlisted Share Prices.</div>
                        </div>
                        <div style={{ "fontSize": "8em", "color": "#008000bd" }}>&rarr;</div>
                    </div>
                    <div style={{ "display": "flex", "height": "0em", "width": "23em", "marginLeft": "4em" }}>
                        <div style={{ "margin": "1em 2em 0em 0em" }}>
                            <div style={{ "backgroundColor": "green", "color": "white", height: "2rem", width: " 2.5rem", "borderRadius": "51px", "textAlign": "center", "padding": "0.5rem 0rem 0rem 0rem", "marginLeft": "3em" }}>2</div>
                            <div style={{ "fontSize": "xxLarge", "fontWeight": "600", "width": "8em", margin: "1rem 0rem 0rem 1rem " }}>Deal Processing</div>
                            <div style={{ padding: "1rem 0rem 0rem 0rem" }}>First, The Buyer Sends Payment To Our Bank Then UnlistedZone Team Starts Processing.</div>
                        </div>
                        <div style={{ "fontSize": "8em", "color": "#008000bd" }}>&rarr;</div>
                        <Box>
                            <ArrowRightAltSharpIcon style={{ "font-size": "8em", "color": "green" }} />
                        </Box>
                    </div>
                    <div style={{ "display": "flex", "height": "0em", "width": "23em", "marginLeft": "4em" }}>
                        <div style={{ "margin": "1em 2em 0em 0em", "padding": "0.2em 0em 0em 1em", "width": "15em" }}>
                            <div style={{ "backgroundColor": "green", "color": "white", height: "2rem", width: " 2.5rem", "borderRadius": "51px", "textAlign": "center", "padding": "0.5rem 0rem 0rem 0rem", "marginLeft": "3em" }}>3</div>
                            <div style={{ "fontSize": "xxLarge", "fontWeight": "600", margin: "1rem 0rem 0rem 1rem" }}>Deal Completion</div>
                            <div style={{ padding: "1rem 0rem 0rem 0rem" }}>After Getting The Payment Our Team Transfer Shares Within 24hrs</div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div style={{ padding: "16px", paddingBottom: '12px' }}>
                <div style={{ textAlign: 'center', fontFamily: "Daikon-regular" }}>
                    <h1 >Process To Buy Private Equity</h1>
                    <p >Recommended For Those Interested In Dealing With Private Equity</p>
                </div>
                <div className="square">
                    <div className="left-line"></div>
                    <div className="dot top-dot"></div>
                    <div className="dot bottom-dot"></div>
                    <div className="number number-top" style={{ fontWeight: "bold" }}>01</div>
                    {/* <!-- <div className="number number-bottom">6</div> --> */}
                    <div className="outer-circle"></div>
                    <div className="left-text" style={{ fontFamily: 'Daikon-regular' }} >At AQT Direct, we conduct thorough research and audits to provide our clients with valuable insights into potential investment opportunities. Our meticulous approach helps identify risks and opportunities, allowing investors to make informed decisions.
                    </div>
                    <div className="center-text" style={{ fontFamily: 'Daikon-regular' }}>Research & Audit</div>
                </div>
                <br />

                <div className="square2">
                    <div className="left-line2"></div>
                    <div className="dot2 top-dot2"></div>
                    <div className="dot2 bottom-dot2"></div>
                    <div className="number2 number-top2" style={{ fontWeight: 'bold' }}>02</div>
                    <div className="outer-circle2"></div>
                    <div className="left-text2" style={{ fontFamily: 'Daikon-regular' }}>We ensure that our clients comply with all regulatory requirements and best practices when structuring equity deals. Our expertise in deal compliance minimizes legal and financial risks, safeguarding our clients' investments.
                    </div>
                    <div className="center-text2" style={{ fontFamily: 'Daikon-regular' }}>Equity Deal Compliances</div>
                </div>

                <br />
                <div className="square">
                    <div className="left-line"></div>
                    <div className="dot top-dot"></div>
                    <div className="dot bottom-dot"></div>
                    <div className="number number-top" style={{ fontWeight: "bold" }}>03</div>
                    {/* <div className="number number-bottom">6</div>  */}
                    <div className="outer-circle"></div>
                    <div className="left-text" style={{ fontFamily: 'Daikon-regular' }}> AQT Direct specializes in creating value for our clients through strategic investment strategies and operational improvements. We work closely with management teams to unlock growth potential and enhance shareholder value.
                    </div>
                    <div className="center-text" style={{ fontFamily: 'Daikon-regular' }}>Value Creation</div>
                </div>

                <br />
                <div className="square2">
                    <div className="left-line2"></div>
                    <div className="dot2 top-dot2"></div>
                    <div className="dot2 bottom-dot2"></div>
                    <div className="number2 number-top2" style={{ fontWeight: "bold" }}>04</div>
                    <div className="outer-circle2"></div>
                    <div className="left-text2" style={{ fontFamily: 'Daikon-regular' }}>Planning an exit strategy is crucial for maximizing returns on investments. AQT Direct assists clients in developing and executing exit plans that align with their financial objectives and market conditions.
                    </div>
                    <div className="center-text2" style={{ fontFamily: 'Daikon-regular' }}>Exit Strategist</div>
                </div>
            </div>
            <Testimonial />
            <EndPage />
            <Footer />
        </>
    )
}