import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import taleoftwo from './taleoftwo.jpg';
import { Typography, Container } from '@mui/material';
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import cochin from '../../pages/Blog/cochin.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const data = [
  {
    image: '',
    content: "A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift "
  },
  {
    image: '',
    content: "A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift "
  },
  {
    image: '',
    content: "A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift "
  },
  {
    image: '',
    content: "A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift "
  },
  {
    image: '',
    content: "A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift "
  },
  {
    image: '',
    content: "A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift "
  },
]

export default function BasicStack() {
  return (

    <Container maxWidth="lg" style={{ padding: '16px' }}>

      {/* <Box sx={{ width: 'auto', margin: "0rem 1rem 0rem 1rem" }}>
        <Stack spacing={2}>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer" } }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <br />
                <Typography variant='p' sx={{ fontSize: "medium",  fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600,  fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer" } }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium" , fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600,  fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer",  fontFamily: 'Daikon-regular' } }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium",  fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600,  fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer",  fontFamily: 'Daikon-regular' } }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium",  fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600,  fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer",  fontFamily: 'Daikon-regular' } }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium",  fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600,  fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer",  fontFamily: 'Daikon-regular' } }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium", fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600, fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer" }, fontFamily: 'Daikon-regular' }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium", fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600 }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
          <Item sx={{ margin: "1em 0em 2em 0em" }}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img src={taleoftwo} alt='taleoftwoimage' style={{ width: "20em", height: "15em" }} />
              </Box>
              <Box sx={{ margin: "0em 4em 1em 2em" }}>
                <Typography variant='h5' component='div' sx={{ color: "black", fontWeight: 600, '&:hover': { color: 'green', cursor: "pointer" }, fontFamily: 'Daikon-regular' }}>A Tale Of Two DRHPs: Decoding IPO-Bound Mobikwik's Business Strategy Shift <br /> From 2021 To 2024</Typography>
                <Typography variant='p' sx={{ margin: "2em 1em 0em 0em", fontSize: "medium", fontFamily: 'Daikon-regular' }}>"Current market conditions and regulatory landscapes for fintech companies, especially in lending, have necenecessitated a more grounded approach," believes Umesh Chandra Paliwal, Cofounder and CEO of Unlistedzone. </Typography>
                <br />
                <br />
                <Typography variant='h6' sx={{ fontWeight: 600, fontFamily: 'Daikon-regular' }}>Read The Full Artical Here</Typography>
              </Box>
            </Box>
          </Item>
        </Stack>
      </Box> */}


      <Box >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {data.map((stock) => (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 350, margin: "1rem 0rem 0rem 0rem", height: 500 }}>
                <CardActionArea>
                  <CardMedia
                    sx={{ objectFit: "fill" }}
                    component="img"
                    height="300"
                    image={cochin}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      fontSize: "large", fontWeight: 600, '&:hover': {
                        color: "green", fontFamily:'Daikon-regular'
                      }
                    }}>
                      Sterlite Power  Partnership with GIC Pte for India's Transmission Projects
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{fontFamily:'Daikon-regular'}}>
                      Sterlite Power Transmission Ltd (SPTL), a subsidiary of Vedanta Ltd, is in the process of transferring three power transmission projects in India to its joint venture with GIC Pte, a Singapore-based s ...
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>

  );
}
