import React from 'react';
import './Footer.css'
import badge from './badge.png';
import apple from './apple.png';
import aqt from '../../assets/images/aqt.png';
import {Link} from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';


export default function     Footer(){
    return(
        <>
            <div className='mainFooter'>
            <div className='blocks'>
            <div className='block1'><img src={aqt} alt='footer'/>
            <p>
            AQT Direct Ltd is a distinguished Private Equity Consultancy dedicated to assisting individuals in becoming Partners and Angel Investors in Early-Stage, High-Growth Potential Public Businesses gearing up for Initial Public Offerings (IPOs). We provide a comprehensive range of services essential for Private Equity Investors, including Market Research, Due Diligence, Auditing, Transaction Execution, and Compliance of Private Placements. Additionally, we offer Legal Support, Deal Documentation, Value Creation Consultation, Industry Specialized Growth Consultation, Strategies for Exiting Investments, and Investor Management Software. By engaging with AQT Direct Ltd and utilizing our services, individuals acknowledge and accept the inherent risks associated with investing in early-stage businesses and agree to hold our consultancy harmless from any liabilities arising from investment activities.
            </p>
            {/* <div style={{display:"flex"}}>
            <a  href='/'>
                <img className='app' src={apple} alt='apple'/></a>
            <a  href='/'>
                <img className='app' src={badge} alt='playstore'/></a>
                </div> */}
            </div>
            {/* <div className='block2'>
                <h1 className='anchorfoot'>Quick Links</h1>
                <div className='anchor'>
                <a className='insideanch' href='/off-market'>Off Market Annexure</a>
                <Link className='insideanch' to='/sebiguidelines'>SEBI Guidelines</Link>
                <Link className='insideanch' to='/frequently-asked-questions'>Frequently Asked Questions</Link>
                <Link to={`/knowledgecenter`} className='insideanch' href='/'>Knowledge Center</Link>
                <Link to={`/blog`} className='insideanch' href='/'>Blog</Link>
                <a className='insideanch' href='/'>Live Platform</a>
                </div>
            </div> */}
            {/* <div className='block3' >
                <h1 className='anchorfoot'>AQT Direct</h1>
                <div className='anchor'> */}
                    {/* <a className='insideanch' href='/'>About Us </a> */}
                    {/* <a className='insideanch' href='/drhp'>DRHP-FILED</a> */}
                    {/* <a className='insideanch' href='/sellipo'>Become Our Partner</a> */}
                    {/* <Link className='insideanch' to='/contactUs'>Contact Us </Link>
                    <Link className='insideanch' to='/privacy'>Privacy</Link> */}
                    {/* <a className='insideanch' href='/'>Privacy Policy </a> */}
                    {/* <a className='insideanch' href='/raisefunding'>Raise Funding</a> */}
                    {/* <Link className='insideanch' to='/terms'>Terms Of Use </Link>
                    <Link className='insideanch' to='/disclaimer'>Disclaimer </Link> */}
                    {/* <Link className='insideanch' to='/banking'>Banking Alert </Link> */}
                {/* </div>
            </div> */}
            <div className='block4'>
                <div >
                <h1 className='anchorfoot1'>Our Offices</h1>
                <p className='pfooter'><LocationOnIcon/>&nbsp;
                <b>Mumbai Office : </b>
                {/* Office No. 616 ,<br/>&nbsp;Sixth Floor, Tower A,<br/> &nbsp;AQT DIRECT LIMITED - <br/>&nbsp; ithum Building,Sector 62, Noida,<br/>&nbsp;Uttar Pradesh 201301 */}
                &nbsp;&nbsp;&nbsp;&nbsp;Enam sambhav C-20, <br/>&nbsp;&nbsp;&nbsp;&nbsp;G block Bandra Kurla Complex <br/> &nbsp;&nbsp;&nbsp;&nbsp;Mumbai ,Maharashtra 400051
                </p>
                
                <p className='pfooter'><LocationOnIcon/>&nbsp;
                <b>Noida Office : </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A-616 iTHUM BUILDING <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; NOIDA SECTOR 62 , <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;UTTAR PREADESH 201309
                </p>
                <p className='pfooter'><LocationOnIcon/>&nbsp;
                <b>Gurugram Office: </b>&nbsp;&nbsp;11A TOWER B3 GF SPAZE I-TECH PARK , <br/>&nbsp;&nbsp;SOHNA ROAD SEC 49 <br/>&nbsp;&nbsp;GURUGRAM 122018
                </p>
                
                <p className='pfooter' id="footmail"><EmailIcon/>&nbsp;info@aqtdirect.com</p>
                <div className='iconfooter'><FacebookRoundedIcon className='icon'/><XIcon className='icon'/><LinkedInIcon className='icon'/><TelegramIcon className='icon'/><YouTubeIcon className='icon'/></div>
                </div>
                
            </div>
        </div>
        <div className='botfoot'>
            ©2018-2024 AQT Direct Limited. All Rights Reserved. &nbsp;&nbsp;&nbsp;&nbsp;CIN : U70200MH2023PLC399088  
        </div>
        </div>
        </>
    )
}