import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import refresh from '../../assets/images/refresh.png';
import Navbar from '../../components/navbar/index';
import Footer from '../../components/Footer/Footer';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function TopSectors(){
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return(
        <>
        <Navbar/>
        <Box sx={{margin:"1rem 1rem 1rem 23rem"}}>
        <TextField id="outlined-basic" label="Search" variant="outlined" sx={{width:500}} />
        <Button variant="contained" sx={{margin:"0.5rem 0rem 0rem 0.5rem",backgroundColor:"green"}} color='success'>Search</Button>
        </Box>
        <Box sx={{  display:"flex",flexWrap:"wrap",margin:"1rem 0rem 3rem 0rem"}}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}} spacing={10} >
            <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
          <Box sx={{width:100,height:150,}}>
            <img src={refresh} alt='refresh' style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box>
          </Item>
        </Grid>
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 0.5rem"}} spacing={10}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} alt='refresh1' style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} alt='refresh2' style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} alt='refresh11' style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} alt='refresh12' style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} alt='refresh13' style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 0rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
      </Grid>
      </Box>
      {/* <Box sx={{  display:"flex",flexWrap:"wrap"}}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}} spacing={10} >
            <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
          <Box sx={{width:100,height:150,}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box>
          </Item>
        </Grid>
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 0.5rem"}} spacing={10}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 0rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
      </Grid>
      </Box>
      <Box sx={{  display:"flex",flexWrap:"wrap"}}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}} spacing={10} >
            <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
          <Box sx={{width:100,height:150,}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box>
          </Item>
        </Grid>
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 0.5rem"}} spacing={10}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 0rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
      </Grid>
      </Box>
      <Box sx={{  display:"flex",flexWrap:"wrap"}}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}} spacing={10} >
            <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
          <Box sx={{width:100,height:150,}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box>
          </Item>
        </Grid>
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 0.5rem"}} spacing={10}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 0rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
      </Grid>
      </Box>
      <Box sx={{  display:"flex",flexWrap:"wrap",margin:"0rem 0rem 4rem 0rem"}}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}} spacing={10} >
            <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
          <Box sx={{width:100,height:150,}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box>
          </Item>
        </Grid>
        <Grid xs={2} style={{width:180,height:150,margin:"4rem 0rem 0rem 0.5rem"}} spacing={10}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 1rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
        <Grid xs={2} spacing={10} style={{width:180,height:150,margin:"4rem 0rem 0rem 0rem"}}>
          <Item sx={{display:"flex",justifyContent:"center",'&:hover':{margin:"1rem 0rem 1rem 0rem"}}}>
            <Box sx={{width:100,height:150}}>
            <img src={refresh} style={{height:80}}/>
            <Typography sx={{'&:hover':{color:"green",cursor:"pointer"}}}>Agriculture</Typography>
            <p>3</p>
          </Box></Item>
        </Grid>
      </Grid>
      </Box> */}
      <Footer/>
        </>
    )
}