import * as React from 'react';
import {useState,useEffect} from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {tableapi,imageApi} from "../../../components/Api/Api";
import Button from '@mui/material/Button';
import aqt from '../../../assets/images/aqt.png';
import hbf from '../../../assets/images/hbf.png';
import incomet from '../../../assets/images/incomet.png';
import indus from '../../../assets/images/indus.png';
import taiki from '../../../assets/images/taiki.png';
import webyne from '../../../assets/images/webyne.png';
import TData from "./TData.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({

  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// function createData(name, unlistedSharePrice, IPOPrice, CMP, gainorloss, image, color) {
//   return { name, unlistedSharePrice, IPOPrice, CMP, gainorloss, image, color };
// }

// const rows = [
//   createData('HBF Direct Limited', "800-3500", 2150, 401.05, -81.35, hbf, redColor),
//   createData('AQT Direct Limited', "200-750", 550, 692.6, 25.93, aqt, greenColor),
//   createData('Taiki Private Limited', "510-1000", 500, 567.75, 13.55, taiki, greenColor),
//   createData('Webyne Private Limited', "150-210", 195, 368.65, 89.05, webyne, greenColor),
//   createData('Indus Private Limited', "225-575", 175, 78.3, -55.26, indus, redColor),
//   createData('Incomet Private Limited', "350-800", 550, 3948.5, 617.91, incomet, greenColor),
//   createData('HBF Direct Limited', "350-420", 353, 344.55, -2.39, hbf, redColor),
//   createData('AQT Direct Limited', "175-350", 305, 171.2, -43.87, aqt, redColor),
//   createData('Taiki Private Limited', "750-1100", 554, 890.7, 60.78, taiki, greenColor),
//   createData('Webyne Private Limited', "650-900", 487, 459.05, -5.74, webyne, redColor),
// ];

export default function TablePage() {
  const [data,setData] = useState([])
  // const [pl,setPl] = useState([])
  

  const FetchData = async ()=>{
    await fetch(tableapi)
    .then(res => res.json())
    .then(res => setData(res.detail.rows))
    }
    useEffect(()=>{
    FetchData()
    },[])

    // console.log("data loss",data)
    const redColor = {
      "backgroundColor": "rgba(255, 0, 0, 0.12)",
      "color": "red", height: "2rem", width: "5rem",
      "borderRadius": "2rem", "textAlign": "center", "margin": "0rem 0rem 0rem 5rem",
      "padding": "0.4rem 0.1rem 0rem 0rem"
    }
    const greenColor = {
      "backgroundColor": "#00800047",
      "color": "green", height: "2rem", width: "5rem",
      "borderRadius": "2rem", "textAlign": "center", "margin": "0rem 0rem 0rem 5rem",
      "padding": "0.4rem 0.1rem 0rem 0rem"
    }
    // let style;
    // const profitLoss = ()=>{
    //   if(data.profit !== null && data.loss === null){
    //     setPl(data.profit)
    //     style = greenColor
    //   }
    //   else if (data.profit === null && data.loss !== null){
    //     setPl(data.loss)
    //     style = redColor
    //   }
    //   else if (data.profit===null && data.loss===null){
    //     setPl('Not Showing')
    //   }
    // }

    // console.log(pl,"profit or loss")

   
  return (
    <>
      <Box sx={{ "backgroundColor": "#EEF5F9", textAlign: 'center', padding:"0rem 4rem 2rem 4rem" }}>
        <Typography variant='h3' sx={{ fontFamily: 'Daikon-regular',padding:"2rem 0rem 0rem 0rem" }}>Past IPO Performance</Typography>
        <Typography component={'p'} style={{ "marginTop": "1rem", "marginBottom": "3rem", fontFamily: 'Daikon-regular' }}>Explore the Historical IPO Performance of Private Equity to Inform Your Investment Strategy Today.</Typography>
        <TableContainer component={Paper} style={{
          "overflow": "hidden",
          "width": "98%", "marginLeft": "0.7rem", "borderRadius": "1rem"
        }}>
          <Table aria-label="customized table"  >
            <TableHead >
              <TableRow >
                <StyledTableCell sx={{ fontSize: "x-large", fontFamily: 'Daikon-regular' }}>Name</StyledTableCell>
                <StyledTableCell align="center" sx={{ fontSize: "x-large", fontFamily: 'Daikon-regular' }}>Share Price</StyledTableCell>
                <StyledTableCell align="center" sx={{ fontSize: "x-large", fontFamily: 'Daikon-regular' }}>IPO Price</StyledTableCell>
                <StyledTableCell align="center" sx={{ fontSize: "x-large", fontFamily: 'Daikon-regular' }}>CMP</StyledTableCell>
                <StyledTableCell align="center" sx={{ fontSize: "x-large", fontFamily: 'Daikon-regular' }}>Gain or Loss</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {data.map((row) => (
                <StyledTableRow key={row.cmpId}>
                  <StyledTableCell component="th" scope="row" sx={{ display: "flex" }} >
                    <img src={`${imageApi}${row.cmpImg}`} alt={row.cmpId} style={{ width: "7rem", height: "3rem",objectFit: 'contain' }} />
                    <Box sx={{ fontSize: "large", margin: "0.7rem 0rem 0rem 1rem" }}>{row.companyName.toUpperCase()}</Box>
                  </StyledTableCell>
                  {row.sharePrice !==null ? <StyledTableCell sx={{ fontSize: "large", fontFamily: 'Daikon-regular' }} align="center">&#8377; {row.sharePrice}</StyledTableCell> :  <StyledTableCell align="center" ><Box variant="contained"><Box variant="contained" > 0 </Box>
                  </Box></StyledTableCell>}
                  
                  {row.sharePrice !== null ? <StyledTableCell sx={{ fontSize: "large", fontFamily: 'Daikon-regular' }} align="center">&#8377; {(row.sharePrice)*2 + 5}</StyledTableCell> :<StyledTableCell align="center" ><Box variant="contained"><Box variant="contained" > 0 </Box>
                  </Box></StyledTableCell>}
                  
                  {row.totalRevenue !==null ? <StyledTableCell sx={{ fontSize: "large", fontFamily: 'Daikon-regular' }} align="center">&#8377; {row.totalRevenue}</StyledTableCell>: <StyledTableCell align="center" ><Box variant="contained"><Box variant="contained" > 0 </Box>
                  </Box></StyledTableCell>}
                  
                 {row.profit !== null ? ( <StyledTableCell align="center" ><Box variant="contained"><Box variant="contained" sx={greenColor}>&uarr; {row.profit} &nbsp;&#37;</Box>
                  </Box></StyledTableCell>) : row.loss !==null ? (<StyledTableCell align="center" ><Box variant="contained"><Box variant="contained" sx={redColor}>&darr; {row.loss} &nbsp; &#37;</Box>
                  </Box></StyledTableCell>) :  <StyledTableCell align="center" ><Box variant="contained"><Box variant="contained" > 0 </Box>
                  </Box></StyledTableCell>  }
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {/* <form style={{"display":"flex"}}>
        <select style={{"margin": "1rem 0rem 0rem 2rem",width: "3rem"}}  name='PageNo.'>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            </select>
            <label style={{"margin": "1rem 0.5rem 0rem 1rem","fontSize":"medium"}}>  Showing 1 to 10 of 15 Records</label>
            </form> */}
          {/* <div style={{"margin": "-2rem 0rem 0rem 70rem",}}>
                <button style={{"backgroundColor":"lightgray","border": "0.1px solid lightgray","color":"green","height": "2.5rem"
                    ,"width": "2rem","borderRadius": "0.2rem"}}>&larr;</button>
                <button style={{"color":"white","backgroundColor":"green","border": "0.1px solid lightgray","height": "2.5rem","width": "2rem",    "borderRadius": "0.2rem"}}>1</button>
                <button style={{"color":"green","border": "0.1px solid lightgray","height": "2.5rem","width": "2rem","borderRadius": "0.2rem","backgroundColor":"white"}}>2</button>
                <button style={{"color":"green","border": "0.1px solid lightgray","height": "2.5rem","width": "2rem","borderRadius": "0.2rem","backgroundColor":"white"}}>&rarr;</button>
                </div> */}
          {/* <Button variant="contained" color='success' sx={{backgroundColor:"#35a848" }}>View More</Button> */}

        </TableContainer>

      </Box>

    </>
  );
}
