import { useState, useEffect } from 'react';
import { Chart as ChartJS, Title, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {api,imageApi} from "../../components/Api/Api"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
ChartJS.register(Title, Tooltip, LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend);

export default function Graph({name}) {
  const [gdata, setGdata] = useState([]);
  const [sales, setSales] = useState([]);
  const [operating, setOperating] = useState([]);
  const [year, setYear] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: [{
      label: 'Sales',
      data: [],
      borderColor: 'blue',
      tension: 0.1,
      showLine: true,
      display: true,
      text: "number of people",
      yAxisId: 'y'
    },
    {
      label: 'Operating Profit',
      data: [],
      borderColor: 'red',
      tension: 0.1,
      showLine: true,
      display: true,
      text: "number of people",
      yAxisId: 'y1'
    }]
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${api}${name}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        // console.log("response of graph",data)
        setGdata(data.detail.projection.rows);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error){
          toast.error("Error Fetching Data !");
          }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (gdata.length > 0) {
      const salesData = gdata.map(g => g.sales).reverse();
      const yearData = gdata.map(y => y.year).reverse();
      const opprofit = gdata.map(o => o.operatingProfit).reverse();
      setSales(salesData);
      setYear([...new Set(yearData)]); // Extract unique years
      setOperating(opprofit);
    }
  }, [gdata]);

  useEffect(() => {
    const newData = {
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          data: fillMissingQuarters(sales, year)
        },
        {
          ...data.datasets[1],
          data: fillMissingQuarters(operating, year)
        }
      ]
    };

    setData(newData);
  }, [sales, operating, year]);

  const fillMissingQuarters = (values, years) => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const newData = [];

    years.forEach(year => {
      quarters.forEach(quarter => {
        const valueIndex = gdata.findIndex(item => item.year === year && `Q${item.quarter}` === quarter);
        if (valueIndex !== -1) {
          newData.push(values[valueIndex]);
        } else {
          newData.push(0);
        }
      });
    });

    return newData;
  };

  const semiXAxis = year.flatMap(y => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    return quarters.map(q => `${y} / ${q}`);
  });

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        position: "left",
        title: {
          display: true,
          text: "Amount in ₹",
          color: "black",
        }
      },
      // y1: {
      //   type: "linear",
      //   beginAtZero: true,
      //   position: "right",
      //   title: {
      //     display: true,
      //     text: "Amount in ₹",
      //     color: "black",
      //   }
      // },
      x: {
        title: {
          display: true,
          text: "Year / Quater",
          color: "black"
        }
      }
    }
  };

  return (
    <> <ToastContainer />
    <Line
      data={{
        ...data,
        labels: semiXAxis
      }}
      options={options}
    />
    </>
  );
}
