import artical from './artical.png';
import './MediaCoverage.css';
import Gridrow from './Gridrow';
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/navbar/index.jsx";
import insight from '../../assets/images/insight.jpg'
import Blog from '../Blog/Blog.js';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function MediaCoverage() {

    const location = useLocation();
    useEffect(()=>{
        window.scrollTo(0,0)
    },[location])
    
    return (
        <>
            <Navbar />
            <div className="mediacover1">
                <div className="mediacover11">

                    <h1>
                        Welcome To AQT India!
                    </h1>
                    {/* <p>Featured-Money Control, Business Standard, Financial Express, Live Mint, Ken and Inc 42</p> */}
                    <p>“ We specialize in Private Equity guiding individuals to create generational. Our focus: steering early-stage, high-growth ventures towards IPO success, in collaboration with discerning Private Equity Investors” </p>
                </div>
                <img src={insight} style={{ width: '35%' }} alt='articalimage' />
            </div>
            <Gridrow />
            {/* <Blog/> */}
            <Footer />
        </>
    )
}