import React from "react";
// import ReactPlayer from 'react-player/youtube';
// import invester from './invester.jpg';
import './SellIPOShares.css';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import dashboard from './dashboard.jpg';
import Button from '@mui/material/Button';
// import together from './together.png';
// import refresh from './refresh.png';
// import clipboard from './clipboard.png';
import Accordianleft from './Accordianleft';
import mail from './mail.png';
import call from './call.png';
import location from './location.png';
import aqtdash from '../../assets/images/aqtdash.jpg';
// import partner from './partner.jpg';
import Footer from "../../components/Footer/Footer.js";
import Navbar from "../../components/navbar/index.jsx";
import stockmarket from '../../assets/images/stockmarket.jpg'
// import stockmarket2 from '../../assets/images/stockmarket2.jpg';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Container from '@mui/material/Container'
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useLocation} from 'react-router-dom'
import { useEffect,useState } from "react";
import axios from "axios";
import {contactapi} from "../../components/Api/Api.jsx";



export default function SellIPOShares() {
    const [formData,setFormData] = useState({
        firstName:"",
    lastName:"",
    email: "",
    phone:"",
    reason:"Business Opportunities",
    message:""
    })

    const handleChange=(e)=>{
        const {name,value} = e.target;
        setFormData({...formData,[name]:value})
    }

    const handleSubmit =async (e) => {
        e.preventDefault();
        try{
        const response = await axios.post(contactapi,formData,{
            headers:{'Content-Type': 'application/json'}
        })
        toast.success(response.data.message)
        setFormData({
            firstName:"",
            lastName:"",
            email: "",
            phone:"",
            message:"",
            reason:"Business Opportunities"
        })
    }
    catch(error){
        toast.error(error?.message)
    }

    }

    
    const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <>
            <Navbar />
            <div style={{ display: "flex", backgroundColor: "#9494ff38", margin: "2.5rem 1rem 1rem 1rem", justifyContent: 'space-between',borderRadius:"2rem" }}>
                <div style={{ width: "40rem", height: "25rem", padding: "8rem 0rem 0rem 4rem", marginLeft: '10%', marginTop: '2%' }}>
                    <div style={{ fontSize: "xx-large", fontWeight: 600 }}>Sell Pre IPO Shares and Earn Commission At <div style={{ display: "flex" }}><div style={{ color: "green" }}>Zero</div>&nbsp;Investment</div></div>
                    <p style={{ fontSize: "x-large" }}>"Join Us And Transform Your Financial Future"</p>
                </div>
                <div style={{ marginRight: '10%' }}>
                    <img src={stockmarket} alt="investerphoto" style={{ width: "30rem", height: "23rem", padding: "4rem 0rem 0rem 0rem", margin: "3rem 0rem 0rem 6rem", backgroundcolor: "#9494ff38" }} />
                </div>
            </div>

            {/*  */}
            <div className="Steps">
                <div style={{ textAlign: 'center' }}>
                    <h1 className="h1dash">Become Our Partner In 3 Easy Steps</h1>
                </div>
                {/* <div className="bigboxipo">
                <div>
                    <div className="number2 number-top2" style={{ fontWeight: 'bold' }}>02</div>
                    <div className="textipo">Sign Up</div>
                </div>
                <div className="arrow"><ArrowForwardIcon style={{ height: "4em", width: "3em", color: "#008000ba" }} /></div>
                <div>
                    <div className="greendot">2</div>
                    <div id="textipo2">Upload Documents</div>
                </div>
                <div><ArrowForwardIcon style={{ height: "4em", width: "3em", color: "#008000ba" }} /></div>
                <div>
                    <div className="greendot">3</div>
                    <div className="textipo">Get Verified</div>
                </div>
            </div> */}
                <div className="data-flow-container">
                    <div className="step-indicator">
                        <div className="step">
                            <div className="step-icon">1</div>
                            <div className="step-label">Sign Up</div>
                            <div className="arrow">&rarr;</div>
                        </div>
                        <div className="step">
                            <div className="step-icon">2</div>
                            <div className="step-label">Upload Documents</div>
                            <div className="arrow">&rarr;</div>
                        </div>
                        <div className="step">
                            <div className="step-icon">3</div>
                            <div className="step-label">Get Verified</div>
                            <div className="arrow">&rarr;</div>
                        </div>
                        <div className="step">
                            <div className="step-icon">4</div>
                            <div className="step-label">User Registered </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sidedash">
                <div>
                    <img className="lowdash" src={aqtdash} alt="dashimage" />
                </div>
                <div className="lowsidedash">
                    <div style={{ fontFamily: 'Daikon-regular' }}><h1>Our Dashboard</h1>
                        <p>AQT Direct has introduced an online partner programme where you can quickly register and upload your documentation. After successful submission, our team will validate your documents and verify your as AQT Direct Official Partner.</p>
                        <Button variant="outlined" style={{ width: "15em", height: "3em", color: "black", fontWeight: 600, border: "1px solid" }}>Primary</Button>
                    </div>
                </div>
            </div>


            <div className="row1">
                <h1>Become Our Partner In 3 Easy Steps</h1>
                <p>"Elevate Your Financial Journey: Partner With Us In Just 3 Simple Steps For Limitless Earning Potential."</p>
                <a href="#form" >   <Button variant="outlined" style={{ width: "13rem", height: "3rem", color: "white", backgroundColor: "#14213D", border: "1px solid", borderRadius: "15px", textDecoration:'none' }}>Join Now  </Button></a>
            </div>
            <div className="youget">
                <div className="youget1">
                    <h1>What You Get?</h1>
                    <p>Research Report, Partner Dashboard and Daily News Of Private Equity Markets.</p>
                </div>
                <div className="youget2">
                    <div className="youget22">
                        <img className="youget2img" src="https://unlistedzone.com/frontend/img/clipboard.png" alt="clip" />
                        <h5 className="youget22h5">Reports</h5>
                        <p>Research Report on all Private Equity.</p>
                    </div>
                    <div className="youget22">
                        <img className="youget2img" src="https://unlistedzone.com/frontend/img/together.png" alt="clip" />
                        <h5 className="youget22h5">Partner Report</h5>
                        <p>Creation Of leads, Check Daily Price <br />movement of Private Equity,<br /> knowledge center and check<br /> commission earned at one place.</p>
                    </div>
                    <div className="youget22">
                        <img className="youget2img" src="	https://unlistedzone.com/frontend/img/refresh.png" alt="clip" />
                        <h5 className="youget22h5">Daily Updates</h5>
                        <p>Daily News and latest updates on Private Equity market.</p>
                    </div>
                </div>
            </div>

            <div className="outeryougetbutton">
                <button className="yougetbutton">Currently, More Than 100+ Partners Are Working With AQT Direct</button>
            </div>
            {/*  */}

            <Container maxWidth="lg">
                <div >
                    <h1 className="accordion">Frequently Asked Questions</h1>
                    <p className="accordionsell">Find Answers To Common Questions That You May Have In Your Mind.</p>
                </div>
                <Accordianleft />
            </Container>

            <Container maxWidth="lg">
                <div className="textyoutube">
                    <div className="textyoutube1"><h1>India's No. 1 Platform to Buy and Sell Pre-IPO</h1></div>
                    <div className="reactplayer">
                        <iframe width="920" height="515" src="https://www.youtube.com/embed/iB_Bs6e4O10?si=wyu4mVVjwfswypa3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </Container>

            {/* <Container maxWidth="lg">
                <div className="formup" >
                    <h1 id="formsellshares">Reach Out To Us</h1>
                    <p>Don't Be Shy Just Tell Us About Yourself And We'll Figure Out The Best Option For You And Your Project. Don't Like Filling Up Forms? Mail Us Then At <a href="/">partners@AQTDirect.com</a></p>
                    <form>
                        <div className="input">
                            <div className="input1">
                                <label>Name </label><input placeholder="Please Enter Your Name" type="text" /></div>
                            <div className="input1"><label>Email </label><input placeholder="Please Enter Your E-Mail" type="email" /></div>
                        </div>
                        <div className="input"><div className="input1"><label>Number
                        </label><input placeholder="Enter Your Number" type="number" /></div>
                            <div className="input1"><label>Subject </label>
                                <input placeholder="Subject" type="text" /></div></div>
                        <div id='input2contact'><label>Where Did You Find Us?</label><select>
                            <option>Select An Option</option>
                            <option>FaceBook</option>
                            <option>Google</option>
                            <option>NewsPaper</option>
                            <option>Word Of Mouth</option>
                            <option>Others</option>
                        </select>
                        </div>
                        <div className="input3">
                            <label>Your Message </label>
                            <input type="text" />
                        </div>
                    </form>
                    <button className="formbutton" type="button">Submit</button>
                </div>
            </Container> */}

            <Container maxWidth="lg">
                <h1 id="form" className="formsellshares">Reach Out To Us</h1>
                <p  style={{ fontFamily: "Daikon-regular" }}>Don't Be Shy Just Tell Us About Yourself And We'll Figure Out The Best Option For You And Your Project. Don't Like Filling Up Forms? Mail Us Then At <a href="/">info@aqtdirect.com</a></p>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label=" First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label=" Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Number"
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    {/* <FormControl fullWidth margin="normal">
                        <InputLabel id="source-label">Where Did You Find Us?</InputLabel>
                        <Select
                            labelId="source-label"
                            id="source"
                            name="source"
                            // value={formData.source}
                            // onChange={handleChange}
                            required
                        >
                            <MenuItem value="Search engine">Search engine</MenuItem>
                            <MenuItem value="Social media">Social media</MenuItem>
                            <MenuItem value="Word of mouth">Word of mouth</MenuItem>
                            <MenuItem value="Advertisement">Advertisement</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl> */}
                    <TextField
                        label="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        required
                    />
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="primary" name="agreedTerms" />}
                            label="I agree to the terms and conditions"
                            required
                        />
                    </Grid>
                    <div style={{ textAlign: 'left' }}>
                        <Button type="submit" style={{ width: '22%', height: '45px' }} variant="contained" color="primary" >
                            Submit
                        </Button>
                    </div>
                </form>
            </Container>



            <div className="navup">
                <div className="navup1">
                    <img id="navup1img" src={location} alt="location"/>
                    <h4 id="navup1h">Visit Us</h4>
                    <p>AQT DIRECT LIMITED - <br />ithum Building,Sector 62, Noida,<br />Uttar Pradesh 201301</p>
                </div>
                <div id="navup12" className="navup1">
                    <img src={call} alt="call" />
                    <h4>Call Us</h4>
                    <p>+91-9667773258</p>
                </div>
                <div className="navup1">
                    <img src={mail} alt="mail" />
                    <h4>Email Us</h4>
                    <p>info@aqtdirect.com</p>
                </div>
            </div>
            <Footer />
            <ToastContainer/>
        </>
    )
}