import indus from "../../assets/images/asset1/indus.png";
import enorvision from "../../assets/images/asset1/enorvision.png";
import hbf from "../../assets/images/asset1/hbf.png";
import taiiki from "../../assets/images/asset1/taiiki.png";
import incomet from "../../assets/images/asset1/incomet.png";
import qpixx from "../../assets/images/asset1/qpixx.png";
import kkring from "../../assets/images/asset1/qpixx.png";
import iamhere from "../../assets/images/asset1/iamhere.svg";


export const dataShow = [{
    id:1,
    name:"INDUSEXPERTS TECHNOLOGIES LIMITED",
    ipoYear:2029,
    imageUrl:indus,
    websiteUrl:"https://indusexperts.com/"
},{
    id:2,
    name:"ENORVISION AIML LTD",
    ipoYear:2030,
    imageUrl:enorvision,
    websiteUrl:"https://enorvision.com/"
},
{
    id:3,
    name:"HBF DIRECT LTD",
    ipoYear:2027,
    imageUrl:hbf,
    websiteUrl:"https://hbfdirect.com/"
},
{
    id:4,
    name:"TAIIKI MEDIA LTD",
    ipoYear:2030,
    imageUrl:taiiki,
    websiteUrl:"https://taiikimedia.com/"
},
{
    id:5,
    name:"INCOMET LEARNING LTD",
    ipoYear:2027,
    imageUrl:incomet,
    websiteUrl:"https://incomet.in/"
},
{
    id:6,
    name:"QPIXX SOLUTIONS LIMITED",
    ipoYear:2030,
    imageUrl:qpixx,
    websiteUrl:""
},
{
    id:7,
    name:"KKRING LIFE INDIA PRIVATE LIMITED",
    ipoYear:2029,
    imageUrl:kkring,
    websiteUrl:""
},
{
    id:8,
    name:"IAMHERE",
    ipoYear:2029,
    imageUrl:iamhere,
    websiteUrl:"https://about.iamhere.app/"

}]