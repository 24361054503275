
import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import Accountants from './Pages/Accountants/index.jsx';
// import Home from './pages/Home/index.jsx';
import Navbar from './components/navbar/index.jsx';
import Footer from './components/Footer/Footer.js';
import MainPage from './pages/MainPage/MainBody.js';
import RaiseFunding from './pages/RaiseFunding/RaiseFunding.js';
import Blog from './pages/Blog/Blog.js';
import Unlisted from './pages/Unlisted/index.jsx';
import Drhp from './pages/Drhp/index.jsx';
import Screener from './pages/Screener/index.jsx';
import SellIPOShares from './pages/SellIPOShares/SellIPOShares.js';
import MediaCoverage from './pages/MediaCoverage/MediaCoverage.js';
import ContactUs from './pages/ContactUs/Contact.js';
import Polymatech from './pages/Polymatech/Polymatech.js';
import KnowledgeCenter from './pages/KnowledgeCenter/KnowledgeCenter.js';
import Offmarket from './pages/Offmarket/index.js';
import Sebi from './pages/Sebi/index.js';
import Frequently from './pages/Frequently/index.js';
import CardViewMore from './pages/MainPage/MainPageContent/CardViewMore.js';
import Disclaimer from './pages/Disclaimer/Disclaimer.js';
import Sebiguiude from './pages/Sebiguide/Sebiguide.js';
import Terms from './pages/TermsOfUse/Terms.js';
import Privacy from './pages/Privacy/Privacy.js';
import BankingAlert from './pages/BankingAlert/BankingAlert.js';
import TopSectors from './pages/TopSectors/TopSectors.js';
import AboutUs from './pages/AboutUs/AboutUs.jsx';
import SignUp from './pages/SignUp/SignUp.js';
import SignIn from './pages/SignUp/SignIn.js';
import UnlistShareCard from './pages/UnlistShareCard/UnlistShareCard.js';
import { useState } from 'react';
import {imageApi} from "./components/Api/Api.jsx"
// import Media from './pages/Media/index.jsx';

function App() {
  const [head,setHead] = useState("")
  const [ids,setIds] = useState("")
  const [imgs,setImgs] = useState("")

  /*const router = createBrowserRouter([
    {
      path:"/",
      element:<MainPage/>,
      children:[
        {path:"/polymatech",
        element:<Polymatech/>}
      ]
    },
    {
      path:"/raisefunding",
      element:<RaiseFunding/>
    },
    {
      path:"/sellipo",
      element:<><Navbar/><SellIPOShares/><Footer/></>
    },
    {
      path:"/unlisted-shares",
      element:<><Navbar/><Unlisted/><Footer/></>,
    },
    {
      path:"/media-coverage",
      element:<><Navbar/><MediaCoverage/><Footer/></>,
    },
    {
      path:"/contactus",
      element:<><Navbar/><ContactUs/><Footer/></>,
    }
  ]) */

  return (
    <>
      {/* <RouterProvider router={router}/> */}

      <Router>
        <Routes>
          {/* <Route path="/" element={<Navigate replace to="/home" />} /> */}
          <Route path={'/'} exact element={<MainPage headings={setHead} idpoly={setIds} images={setImgs} />} />
          <Route path={'/raisefunding'} exact element={<RaiseFunding />} />
          <Route path={'/about'} exact element={<AboutUs />} />
          <Route path={'/business-opportunities'} exact element={<SellIPOShares />} />
          <Route path={'/limited-partnerships'} exact element={<Unlisted headings={setHead} idpoly={setIds} images={setImgs} />} />
          {/* <Route path={'/drhp'} exact element={<Drhp />} /> */}
          {/* <Route path={'/screener'} exact element={<Screener />} /> */}
          <Route path={'/insights'} exact element={<MediaCoverage />} />
          <Route path={'/contactus'} exact element={<ContactUs />} />
          <Route path={'/company'} exact element={<Polymatech heading={head} id={ids} img={imgs}/>} />
          <Route path={'/blog'} exact element={<Blog />} />
          <Route path={'/knowledgecenter'} exact element={<KnowledgeCenter />} />
          <Route path={'/off-market'} exact element={<Offmarket />} />
          {/* <Route path={'/sebi'} exact element={<Sebi />} /> */}
          <Route path={'/frequently-asked-questions'} exact element={<Frequently />} />
          <Route path={'/companylist'} exact element={<CardViewMore headings={setHead} idpoly={setIds} />} />
          <Route path={'/disclaimer'} exact element={<Disclaimer />} />
          <Route path={'/sebiguidelines'} exact element={<Sebiguiude />} />
          <Route path={'/terms'} exact element={<Terms />} />
          <Route path={'/privacy'} exact element={<Privacy />} />
          <Route path={'/banking'} exact element={<BankingAlert />} />
          <Route path={'/topsectors'} exact element={<TopSectors />} />
          {/* <Route path={'/signup'} exact element={<SignUp />} /> */}
          {/* <Route path={'/signin'} exact element={<SignIn />} /> */}
          <Route path={'/unlistsharecard'} exact element={<UnlistShareCard />} />
          {/* <Route path={'/signin'} exact element={<Signin />}/>
        <Route path={'/signup'} exact element={<Signup />}/>
        <Route path={'/forgot'} exact element={<Forgot />}/>
        <Route path={'/reset_password'} exact element={<Resetting />}/>
        <Route path={'/settings'} exact element={<Settings />}/>
        <Route path={'/features'} exact element={<Features />}/>
        <Route path={'/defi'} exact element={<Defi />}/>
        <Route path={'/pow'} exact element={<Pow />}/>
        <Route path={'/passive'} exact element={<Passive/>}/>
         <Route path={'/signupp'} exact element={<Signupp  />}/>

        <Route path={'/nft'} exact element={<Nft/>}/>
        <Route path={'/airdrop'} exact element={< Airdrop />}/>
        <Route path={'/about'} exact element={<About/>}/>
         <Route path={'/checkout'} exact element={<Checkout/>}/> 
        <Route path={'/integration'} exact element={<Integration />}/>
        <Route path={'/accountants'} exact element={<Accountants />}/>
        <Route path={'/price'} exact element={<Pricing />}/>
        <Route path={'/blogs'} exact element={<Resources />}/>
        <Route path={'/privacy-policy'} exact element={<Privacy />}/>
        <Route path={'/terms'} exact element={<Terms />}/>
        <Route path={'/dashboard'} exact element={<Dashboard />}/>
        <Route path={'/imports'} exact element={<Import />}/>
        <Route path={'/transactions'} exact element={<Transactions />}/>
        <Route path={'/reports'} exact element={<Reports />}/> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
