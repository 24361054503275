import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import artical1 from '../../assets/images/artical1.png';
import artical from '../MediaCoverage/artical.png';
import Gridrow from '../MediaCoverage/Gridrow';
import Navbar from '../../components/navbar/index';
import Footer from '../../components/Footer/Footer';
import { Padding } from "@mui/icons-material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";



export default function KnowledgeCenter() {
    const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <>
            <Navbar />
            <div style={{padding:'8px'}}>
            <Typography sx={{ textAlign: 'center', fontFamily: "Daikon-regular" }}><h1>Knowledge Center</h1> </Typography>
            <div style={{textAlign: 'center'}}>
                <Typography variant='p' sx={{ fontFamily: "Daikon-regular" }}>"Empower Your Investments: Read Our In-Depth Articles for Comprehensive Insights on Buying, Selling, and Managing Risks in the Unlisted Market."</Typography>
            </div>
            <Box sx={{ textAlign: 'center' }}>
                <img src={artical1} alt="artical1" style={{ height: "20rem", paddingTop:'18px' }} />
            </Box>
            </div>
         
            <div className="mediacover1">
                <div className="mediacover11">
                    <p>Featured-Money Control, Business Standard, Financial Express, Live Mint, Ken and Inc 42</p>
                    <h1>
                        Welcome To Unlistedzone India!
                    </h1>
                    <p>"Building Trust Through Expertise: Our Founder are regularly featured in media,offering credible insights on unlisted and pre-IPO shares for confident investing." </p>
                </div>
                <img src={artical} alt='articalimage' />
            </div>
            <Gridrow />
            <Footer />
        </>
    )
}