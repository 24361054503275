import Footer from "../../components/Footer/Footer.js";
import Navbar from "../../components/navbar/index.jsx";
import './Contact.css'
import { TextField, Select, MenuItem, FormControl, InputLabel, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import Container from '@mui/material/Container'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import contactus from "../../assets/images/contactus.jpg";
import contact from "../../assets/images/contact.jpg";
import contact1 from "../../assets/images/contact1.jpg";
import {contactapi} from "../../components/Api/Api.jsx";
import axios from "axios";

export default function Contact() {
    const [formData,setFormData] = useState({
        firstName:"",
        lastName:"",
        email: "",
        phone:"",
        message:"",
        reason:"Contact Us"
    })

    const handleChange =(e)=>{
        const {name,value} = e.target;
        setFormData({...formData,[name]:value})
    }
    // console.log(formData,"form data")

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try{
            const response =  await axios.post(contactapi,formData,{
                headers:{'Content-Type': 'application/json'}
            })
            toast.success(response.data.message)
            setFormData({
                firstName:"",
                lastName:"",
                email: "",
                phone:"",
                message:"",
                reason:"Contact Us"
            })
        }
        catch(error){
        toast.error(error?.message)
        }
        }

    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    // console.log("formdata",formData)
    return (
        <>
            <Navbar />
            <Box sx={{margin:"2rem 0rem 0rem 0rem"}}>
            
            <img src={contact1} alt="contactus" className="imageContact"/>
            <div className="contacthead">Contact Us</div>
            </Box>
            <Box sx={{padding:"3rem 5rem 2rem 7rem"}}>
            <Box>
                <h1 id="form" className="formsellshares">Reach Out To Us</h1>
                <p style={{ fontFamily: "Daikon-regular" }}>Ready to take the next step? Contact AQT Direct Limited today to learn more about our services and how we can help you achieve your investment goals. Whether you're an investor looking for opportunities or a business seeking strategic advice, our team is here to assist you. 
                 {/* <a href="/">partners@AQTDirect.com</a> */}
                 </p>
                 <Typography sx={{fontWeight:600,padding:"1rem 0rem 1rem 7rem"}}>Get in touch with us via phone, email, or through our online contact form. We look forward to hearing from you!</Typography>
                 <Box sx={{display:"flex"}}>
                 {/* <Card sx={{ maxWidth: 345, maxHeight: 400 }}>
    <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            Registered Address
        </Typography>
        <Typography sx={{ display: "flex" }} variant="body2" color="text.secondary">
            <LocationOnIcon /><Box sx={{ fontWeight: 600 }}>Registered Address:</Box> Enam sambhav C-20, G block Bandra Kurla Complex, Mumbai Maharashtra 400051
        </Typography>
        <Typography sx={{ display: "flex" }} variant="body2" color="text.secondary">
            <LocationOnIcon /><Box sx={{ fontWeight: 600 }}>Branch Address:</Box> 616, Tower A, ithum Building, Sector 62, Noida, Uttar Pradesh 201301
        </Typography>
        <Typography sx={{ display: "flex" }} variant="body2" color="text.secondary">
            <EmailIcon /><Box sx={{ fontWeight: 600 }}>Mail Us:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; info@aqtdirect.com <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;support@aqtdirect.com
        </Typography>
    </CardContent>
</Card> */}

                    <Box sx={{margin:"5rem 0rem 0rem -3rem"}}>
                    <Container sx={{border:"2px solid darkgray",borderRadius:"1rem",height:"20rem"}}><Typography sx={{fontWeight:600,padding:"1rem "}}>Meet Us In Our Office</Typography>
                    <Box sx={{display:"flex"}}>&nbsp;&nbsp;&nbsp;&nbsp;<LocationOnIcon/><Box sx={{fontWeight:600}}>Registered Address :</Box> Enam sambhav C-20, G block Bandra Kurla Complex , <br/>Mumbai Maharashtra 400051 </Box>
                    <Box sx={{display:"flex",padding:"1rem 0rem 0rem 0rem"}}>&nbsp;&nbsp;&nbsp;&nbsp;<LocationOnIcon/><Box sx={{fontWeight:600}}>Branch Address:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;616, Tower A,ithum &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Building,Sector 62, Noida,Uttar &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pradesh 201301 </Box>
                    <br/>
                    {/* <br/> */}
                    {/* <Box sx={{display:"flex"}}><LocationOnIcon/><Box sx={{fontWeight:600}}>Branch Address :</Box> 616 A Tower, <br/>ithum Tower, Sector 61 <br/>Noida 201309 </Box> */}
                    <Box sx={{display:"flex"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<EmailIcon/><Box sx={{fontWeight:600}}>Mail Us:</Box>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;info@aqtdirect.com <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;email@aqtdirect.com </Box>
                    </Container>
                    </Box>
                    <Box sx={{disaplay:"flex",flexDirection:"column"}}>
                        <Typography variant="h5" sx={{padding:"1rem 0rem 2rem 5rem",fontWeight:600}}>Contact Us</Typography>
                <form onSubmit={handleSubmit} className="formcontact">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} >
                            <TextField sx={{width:"20rem"}}
                                size="small"
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <TextField sx={{width:"20rem"}}
                                size="small"
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField sx={{width:"20rem"}}
                                size="small"
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField sx={{width:"20rem"}}
                                size="small"
                                label="Number"
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={5}>
                            <TextField 
                                label="Subject"
                                name="subject"
                                // value={formData.subject}
                                // onChange={handleChange}
                                
                                required
                            />
                        </Grid> */}
                    </Grid>
                    <Box sx={{display:"flex",flexDirection:"column"}}>
                    {/* <FormControl size="small"  margin="normal" sx={{width:"30rem"}}>
                        <InputLabel  id="source-label">Where Did You Find Us?</InputLabel>
                        <Select
                            labelId="source-label"
                            id="source"
                            name="source"
                            // value={formData.source}
                            // onChange={handleChange}
                            required
                        >
                            <MenuItem value="Search engine">Search engine</MenuItem>
                            <MenuItem value="Social media">Social media</MenuItem>
                            <MenuItem value="Word of mouth">Word of mouth</MenuItem>
                            <MenuItem value="Advertisement">Advertisement</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl> */}
                    {/* <TextField 
                        label="Reason"
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        
                        multiline
                        rows={2}
                        margin="normal"
                        required
                    /> */}
                    <TextField 
                        label="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        
                        multiline
                        rows={4}
                        margin="normal"
                        required
                    />
                    </Box>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="primary" name="agreedTerms" />}
                            label="I agree to the terms and conditions"
                            required
                        />
                    </Grid>
                    <Box sx={{ textAlign: 'left' }}>
                        <Button type="submit" style={{ width: '22%', height: '45px' }} variant="contained" color="primary" >
                            Submit
                        </Button>
                    </Box>
                </form>
                </Box>
                </Box>
            </Box>
            </Box>
            <Footer />
            <ToastContainer/>
        </>
    )
}